import React from "react"
import { FaArrowDown } from "react-icons/fa"
import SEO from "../components/seo"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import LiamBioPic from "../images/boy-golden-headshot-2023.jpg"
import Oolie from "../images/oolie.gif"
import Dylan from "../images/dylan.gif"
import Roman from "../images/roman.gif"
import Tizzy from "../images/tizzy.gif"
import Austy from "../images/austy.gif"
import Corey from "../images/corey.gif"
import SorenGif from "../images/soren.gif"
import Previa from "../images/previa.gif"
import Graham from "../images/graham.gif"
import Shauna from "../images/shauna.gif"
import Jon from "../images/jon.gif"
import Dani from "../images/dani-nash.gif"
import UnderConstruction from "../images/underConstruction.gif"

import "../styles/about.css"
import "../styles/utilities.css"

const About = () => {
  const title = "About Our Church"
  const description =
    "Learn about The Church of Better Daze. Meet our minister, Boy Golden. Get to know our wonderful staff."

  return (
    <>
      <SEO title={title} description={description} />
      <div className="staff-page-container">
        <Navbar />
        <div className="content-container about-shadow ">
          <div id="bio" className="flexColumn album-box ">
            <h1 className="txt-white center-text txt-shadow">
              Boy Golden - Minister
            </h1>
            <img
              className="bio-img box-shadow"
              src={LiamBioPic}
              alt="Ya Boy Golden, Liam Duncan, Minister"
            ></img>
            <p className="bio-txt txt-white bg-space tacky-border2">
              From stoner-boy to country-boy, blue-boy to a golden-voiced-man,
              Boy Golden has embodied them all. Now, as our guide, Boy Golden
              leads us through this next experience with kindness and humour,
              straight down Highway 5 and headlong into
              <em> For Eden</em>.
              <br />
              <br />
              Ten stories told from his heart, his dreams and his real life, For
              Eden is about searching for things — like dreams, love and better
              versions of himself, all wrapped up in some idea of a perfect
              place, be it real or imagined. But Paradise isn’t an outer realm;
              it’s the peace Boy Golden’s found within.
              <br />
              <br />
              Since releasing his debut album, <em>
                Church of Better Daze
              </em>{" "}
              (2020), Boy Golden has been immersed in the journey: one where
              he’s traveled inward towards himself and the other traversing the
              observable world. Touring for hundreds of days, spending time at
              home in the studio alone or with friends, Boy Golden has been
              writing, writing, writing. From the lyrics, to the music, to the
              accompanying behind the scenes stories and newsletters, Boy
              Golden’s honesty and clarity are magnificent. At times they
              magnify small wells of sadness, bringing forth the tears; other
              times they widen smiles, decimate guilt and fear, and others
              still, his songwriting eases the boundaries between folk,
              bluegrass, Americana and pop music.
              <br />
              <br />
              A compass and a lighthouse, a roadmap and a set of postcards, For
              Eden forms a neat collection of experiences, remembered with
              tenderness and beauty. It serves as a travel companion for others
              on their respective journeys. It’s nice to have someone along for
              the ride — and someone waiting for you at home.
              <br />
              <br />
              If home is where Boy Golden’s heart is, be it in the big city,
              tiny town, on the road or on a record, <em>For Eden</em> beats
              solidly, passionately, emphatically for a life and for love in the
              moments we have.
              <span className="myspace-link">
                <a
                  href="http://www.boygolden.ca"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h5 className="center-text">
                    Visit Boy Golden's Myspace Page!
                  </h5>
                </a>
              </span>
            </p>
          </div>
          <hr />
          <div>
            <div className=" flexColumn  album-box">
              <h2 className="txt-white center-text txt-shadow">Who We Are </h2>

              <div className=" bio-box bio-txt txt-primary tacky-border1 bg-space">
                <p className="txt-white  ">
                  <span className="txt-secondary">
                    The Church of Better Daze
                  </span>{" "}
                  was founded by{" "}
                  <span className="txt-secondary">Boy Golden</span> in the year{" "}
                  <span className="txt-secondary">2020 A.D.</span> (Afternoon
                  Delight).
                  <br /> <br /> One day,{" "}
                  <span className="txt-secondary">Boy Golden</span> awoke with a
                  simple notion in his head;{" "}
                  <em className="txt-highlight">
                    today is going to be a better day than the last one.
                  </em>{" "}
                  <br />
                  <br />
                  He got out of bed and{" "}
                  <span className="txt-secondary">meditated</span>. He sat at
                  his desk and wrote in his{" "}
                  <span className="txt-secondary">journal</span>. He did some
                  moderately{" "}
                  <span className="txt-secondary">taxing exercise</span>. He
                  made himself a couple cups of{" "}
                  <span className="txt-secondary">coffee</span>, and a nice
                  <span className="txt-secondary"> breakfast</span>. He rolled
                  himself a little{" "}
                  <span className="txt-secondary">
                    joint<span className="txt-primary">™</span>
                  </span>
                  , and went outside to take a few deep breaths of{" "}
                  <span className="txt-secondary">fresh air</span>, mixed with a
                  few deep breaths of the joint.{" "}
                  <span className="txt-highlight">
                    Already, things were looking up.
                  </span>{" "}
                  <br />
                  <br /> He spent time writing, he called a friend. They made
                  some music, and the day came to an end. And when he woke up,
                  the very next day, he said to himself{" "}
                  <em className="txt-secondary">
                    'hey, that was pretty great!"
                  </em>
                  . So from that day forward, Boy Golden had purpose. Enjoying
                  the day, and making good music.{" "}
                  <span className="txt-secondary">
                    <em>
                      The Church of Better Daze is here to help anyone who wants
                      to enjoy a better day or a better series of daze
                    </em>
                  </span>
                  . To start, we recommend throwing on some{" "}
                  <span className="txt-highlight">Boy Golden</span>, and
                  mindfully enjoying a toke, a beverage, or a walk around the
                  neighbourhood. We hope you'll join the church and come along
                  for the ride. <br />
                  <br />
                </p>
                <div className="down-text center-text">
                  <strong>
                    <h5>
                      <a href="#staff">
                        Meet some of the staff who have already joined!{" "}
                      </a>
                    </h5>

                    <br />
                    <br />
                    <div className="arrow-group">
                      <FaArrowDown /> <FaArrowDown /> <FaArrowDown />{" "}
                      <FaArrowDown /> <FaArrowDown /> <FaArrowDown />{" "}
                      <FaArrowDown /> <FaArrowDown />
                    </div>
                  </strong>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div id="staff"></div>
          <hr />
          <div className="staff-heading-container">
            <h3 className="txt-white center-text txt-shadow">Support Staff</h3>
          </div>

          <table className="staff-table tacky-border">
            <thead>
              <tr>
                <th>Name</th>
                <th>Face</th>
                <th>Job Title</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="name-box"> Ooly</td>
                <td className="gif-box">
                  <img
                    className="faces"
                    src={Oolie}
                    alt="gif of Kris Ulrich"
                  ></img>
                </td>

                <td> Director of Hospitality / Guitar-Slinger</td>
              </tr>
              <tr>
                <td className="name-box"> Brother Roman</td>
                <td className="gif-box">
                  <img
                    className="faces"
                    src={Roman}
                    alt="gif of Roman Clarke"
                  ></img>
                </td>

                <td>
                  Resident Monk / Drum Player <br />
                  (doesn't play too loud though.. don't worry)
                </td>
              </tr>
              <tr>
                <td className="name-box">Field Guy</td>
                <td className="gif-box">
                  <img
                    className="faces"
                    src={Dylan}
                    alt="gif of Dylan McDonald aka. Field Guide"
                  ></img>
                </td>
                <td> Camp Director</td>
              </tr>

              <tr>
                <td className="name-box">Austy P.</td>
                <td className="gif-box">
                  <img
                    className="faces"
                    src={Austy}
                    alt="gif of Austy P."
                  ></img>
                </td>
                <td> Site Janitor / Guitar-Slinger</td>
              </tr>
              <tr>
                <td className="name-box">Mama Tizzy</td>
                <td className="gif-box">
                  <img
                    className="faces"
                    src={Tizzy}
                    alt="gif of Mama Tizzy"
                  ></img>
                </td>
                <td> Church Band Director / Tambourine Shaker</td>
              </tr>
              <tr>
                <td className="name-box">Dani Nash</td>
                <td className="gif-box">
                  <img
                    className="faces"
                    src={Dani}
                    alt="gif of Dani Nash"
                  ></img>
                </td>
                <td> Drumset, Vox, and good vibes</td>
              </tr>
              <tr>
                <td className="name-box">Uncle Corey</td>
                <td className="gif-box">
                  <img
                    className="faces"
                    src={Corey}
                    alt="gif of Uncle Corey"
                  ></img>
                </td>
                <td> Frequent Attendee / Bass-Slinger</td>
              </tr>
              <tr>
                <td className="name-box">Jonboy Smith (Verified)</td>
                <td className="gif-box">
                  <img className="faces" src={Jon} alt="gif of Jon Smith"></img>
                </td>
                <td>Perc & Positivity</td>
              </tr>
              <tr>
                <td className="name-box">St. Shauna</td>
                <td className="gif-box">
                  <img
                    className="faces"
                    src={Shauna}
                    alt="gif of St. Shauna - CFO"
                  ></img>
                </td>
                <td> CFO</td>
              </tr>
              <tr>
                <td className="name-box">Deacon Graham</td>
                <td className="gif-box">
                  <img
                    className="faces"
                    src={Graham}
                    alt="gif of Deacon Graham - Deliverer of Sermons"
                  ></img>
                </td>
                <td> Deliverer of Sermons</td>
              </tr>
              <tr>
                <td className="name-box"> Soren</td>
                <td className="gif-box">
                  <img
                    className="faces"
                    src={SorenGif}
                    alt="gif of Soren's face"
                  ></img>
                </td>

                <td>Website Guy</td>
              </tr>
              <tr>
                <td className="name-box"> Church Transport </td>
                <td className="gif-box">
                  <img
                    className="faces"
                    src={Previa}
                    alt="Toyota Previa Gif"
                  ></img>
                </td>

                <td> Toyota Previa </td>
              </tr>
              <tr>
                <td className="name-box"> Current Openings </td>
                <td className="gif-box">
                  <img
                    className="under-construction"
                    src={UnderConstruction}
                    alt="under construction gif"
                  ></img>
                </td>

                <td> Send a cover letter using the form below! </td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <hr />
          <br id="apply" />
          <br />
          <form
            name="join-staff"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="join-staff" />

            <div className="form-container bg-white tacky-border3 bg-mandala">
              <h3 className="txt-secondary center-text txt-shadow2">
                Join Our Staff
              </h3>
              <div className="application-instructions  ">
                <hr />
                <p>
                  <ul>
                    <li>
                      Please submit a (250 word minimum) cover letter to apply.
                    </li>{" "}
                    <li>
                      Successful candidates will be contacted for additional
                      information and will be featured on our staff table.
                    </li>
                  </ul>
                </p>
                <hr />
              </div>

              <br />

              <div className="flex-labels">
                <label htmlFor="name">Name *</label>
                <input
                  id="name"
                  type="text"
                  name="name"
                  maxlength="100"
                  required
                />
              </div>
              <div className="flex-labels">
                <label htmlFor="email">Email *</label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  maxlength="100"
                  required
                />
              </div>
              <div className="flex-labels">
                <label htmlFor="job-title">Job Title *</label>
                <input
                  id="job-title"
                  type="text"
                  name="job"
                  maxlength="100"
                  required
                />
              </div>

              <div className="flex-labels">
                <label htmlFor="message">Cover Letter *</label>
                <textarea
                  id="message"
                  className=" message-box message-box-cover-letter"
                  name="message"
                  rows="10"
                  cols="10"
                  required
                ></textarea>
              </div>

              <p>
                <button type="submit">Send</button>
              </p>
            </div>
          </form>
        </div>

        <Footer />
      </div>
    </>
  )
}

export default About
